import React, { useEffect, useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import registerInterestImage from "./../assets/image/svg/register-icon.svg"
import { Button, Section } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const ContentWrapper = styled.div`
    p {
        margin-bottom: 0;
        padding-top: 8px;
    }
`;

const SuccessRegisterInterest = (props) => {
    const gContext = useContext(GlobalContext);
    useEffect(() => {
        gContext.goResetRegistrationData()
        if (typeof window !== 'undefined') {
            localStorage.removeItem('registrationData')
            gContext.goResetRegistrationData()
        }
    }, [])

    const renderContent = () => {

        const pageNumber = props?.location?.search;

        let buildNumber = 0;

        if (pageNumber) {
            buildNumber = parseInt(pageNumber.split('=')[1]);
        }

        if (buildNumber === 1) {
            return <>
                <h2>Great News!</h2>
                <p>We are currently designing our ultrafast, full-fibre network for your area.</p>
                <p>We will get in touch as soon as your home becomes eligible to connect to the UK’s fastest, full-fibre network.</p>
                <p>Why should you be excited? Up to 8000 Mbps speeds! That's a whopping x200 faster than the average UK home connection.</p>
                <p>And if that wasn’t exciting enough, our package prices start from as low as £22.99 per month.</p>
                <p>Be sure to share with your neighbours that we're coming soon!</p>
                <p>We can’t wait to welcome you to our network.</p>
                <p>The YouFibre team</p>
            </>
        } else if (buildNumber === 2) {
            return <>
                <h2>Great News!</h2>
                <p>We are currently building our ultrafast, full-fibre network in your area and it shouldn’t be too long before we can connect you!</p>
                <p>We will get in touch as soon as your home becomes eligible to connect to the UK’s fastest, full-fibre network.</p>
                <p>Why should you be excited? Up to 8000 Mbps speeds! That's a whopping x200 faster than the average UK home connection.</p>
                <p>And if that wasn’t exciting enough, our package prices start from as low as £22.99 per month.</p>
                <p>Be sure to share with your neighbours that we're coming soon!</p>
                <p>We can’t wait to welcome you to our network.</p>
                <p>The YouFibre team</p>
            </>
        } else {
            return <>
                <h2>We hear you, you'd love to connect to our ultrafast, full-fibre network.</h2>
                <p>Unfortunately, at the moment, there aren’t any immediate plans to bring our network to your location.</p>
                <p>However, your registration of interest does mean that it will be on our list of places to explore for our future build plans!</p>
                <p>The YouFibre team</p>
            </>
        }
    }

    return (
        <Section className="green-bg text-center">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8} md={10} sm={8}>
                        <img src={registerInterestImage} className="mt-30 mb-30" />
                        <ContentWrapper>
                            {renderContent()}
                        </ContentWrapper>
                        <Button
                            className="mt-50 light-bg"
                            onClick={() => {
                                navigate('/');
                            }}
                        >Return to homepage</Button>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default SuccessRegisterInterest